import moment from 'moment'

import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.orders-v2+json'

const MEDIA_TYPE_CONSIGNMENT_V2 = 'application/vnd.budbee.consignments-v2+json'

const webapi = (orderIdOrToken) =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('orders')
        .path(orderIdOrToken)
        .path('intervals')

export const getIntervals = (orderToken, n) =>
    webapi(orderToken)
        .path(n)
        .get()

export const getSchedule = (orderToken, assumeAtDestination) =>
    new Webapi()
        .authorization(auth.get())
        .path('orders')
        .path(orderToken)
        .path('schedules')
        .path('budbee')
        .queryParam('from', moment().format('YYYY-MM-DD'))
        .queryParam('assumeAtDestination', assumeAtDestination)
        .get()

export const bookConsignment = (orderToken, deliveryAttempt, timeWindow, assumeAtDestination) =>
    new Webapi()
        .authorization(auth.get())
        .accept(MEDIA_TYPE_CONSIGNMENT_V2)
        .contentType(MEDIA_TYPE_CONSIGNMENT_V2)
        .path('orders')
        .path(orderToken)
        .path('consignments')
        .queryParam('assumeAtDestination', assumeAtDestination)
        .post({
            consignmentId: deliveryAttempt != null ? deliveryAttempt.id : null,
            responsible: 'BUDBEE',
            timeWindow,
            type: 'DELIVERY',
        })
