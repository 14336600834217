import React from 'react'

import { Button, Modal, Table } from 'react-bootstrap'
import { getIngridDedupParcels, recallIngridDedupParcels } from '../../utils/parcel-webapi'

class IngridDedup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            parcels: [],
            inProgress: false,
        }
    }

    componentDidMount() {
        this.fetchParcels()
    }

    fetchParcels() {
        getIngridDedupParcels().then((resp) => this.setState({ parcels: resp.parcels }))
    }

    recallParcels() {
        recallIngridDedupParcels().then(() => this.setState({ inProgress: false }))
    }

    render() {
        const { parcels, inProgress } = this.state
        const onRecall = () => {
            this.setState({ inProgress: true })
            this.recallParcels()
        }
        return (
            <div className="ingrid-dedup-container">
                <div className="ingrid-dedup-btns">
                    <Button bsStyle="warning" onClick={onRecall} disabled={inProgress}>
                        Recall
                    </Button>
                </div>
                {inProgress && (
                    <Modal show onHide={null}>
                        <Modal.Header closeButton={false}>
                            <Modal.Title>Recalling Parcels</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                Recalling parcels in progress. This modal will close when complete. Do <b>not</b>{' '}
                                reload/close this tab.
                            </p>
                        </Modal.Body>
                    </Modal>
                )}
                <Table>
                    <thead>
                        <tr>
                            <th>Order</th>
                            <th>Package</th>
                        </tr>
                    </thead>
                    <tbody>
                        {parcels.map((parcel) => (
                            <tr>
                                <td>
                                    <a href={`/admin/orders/${parcel.orderToken}`}>{parcel.orderToken}</a>
                                </td>
                                <td>{parcel.packageId}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default IngridDedup
